import { Card, CardTitle, CardBody, CardText, Col, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import PlaceButton from './place-button'
import { useSelector } from 'react-redux'
import AddPlacesModal from './add-places-modal'
import { useState } from 'react'

const propTypes = {
  canFly: PropTypes.bool,
  handleClick: PropTypes.func
}

const NavPanel = ({ canFly = false, handleClick = () => {} }) => {
  const places = useSelector(state => state.places)
  const index = useSelector((state) => state.index)
  const [modal, setModal] = useState(false)
  const [isShowNavigation, setIsShowNavigation] = useState(true)
  const toggle = () => setModal(!modal)
  const handleshowNavigation = () => {
    setIsShowNavigation(!isShowNavigation)
  }

  return (
    <div className='nav-panel-pos' style={{ cursor: 'pointer' }}>
      {
        isShowNavigation && !canFly
          ? (
            <Card className='d-flex justify-content-center align-items-center' style={{ width: '375px', border: '1px solid black', zIndex: 1000 }}>
              <CardBody>
                <CardTitle className='text-center' tag='h5'>
                  請選擇地點
                  <div className='float-end justify-content-center align-items-center img-button'>
                    <img src='/media/box-arrow-in-down-left.svg' alt='down-left' onClick={handleshowNavigation} />
                  </div>
                </CardTitle>
                <div className='card-content'>
                  {places && places.map(place => (
                    <PlaceButton key={place.id} id={place.id} placeName={place.name} longitude={place.longitude} latitude={place.latitude} handleClick={handleClick} />
                  ))}
                </div>
                {index.role === 'admin' &&
                  <Card className='place-button' style={{ width: '340px', border: '1px solid white' }} onClick={toggle}>
                    <CardBody>
                      <Row>
                        <Col md='2'>
                          <img src='/media/plus-circle.svg' alt='place icon' style={{ width: '30px' }} />
                        </Col>
                        <Col>
                          <CardTitle tag='h5'>新增地點 ADD PLACES</CardTitle>
                          <CardText>點擊新增地點</CardText>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>}
                <AddPlacesModal modal={modal} toggle={toggle} />
              </CardBody>
            </Card>)
          : <img className='hide-navigation-button' src='/media/airplane-fill.svg' alt='airplane' onClick={handleshowNavigation} />
      }
    </div>
  )
}

NavPanel.propTypes = propTypes

export default NavPanel
