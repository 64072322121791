import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Viewer, ImageryLayer, Cesium3DTileset, CameraFlyTo } from 'resium'
import { Ion, IonResource, IonImageryProvider, Cartesian3, SceneTransforms, Math as CMath } from 'cesium'
import { get } from './utils/httpAgent'
import { useDispatch, useSelector } from 'react-redux'
import NavPanel from './components/navigation-panel'
import EmbeddedWebPage from './components/embedded-web-page'
import EntityComponent from './components/entity-component'
import 'bootstrap/dist/css/bootstrap.min.css'

const Earth = () => {
  const initialPosition = Cartesian3.fromDegrees(121.1, 19, 1000000)
  const initialOrientation = {
    heading: CMath.toRadians(0),
    pitch: CMath.toRadians(-60),
    roll: CMath.toRadians(5)
  }
  const [imageryProvider, setImageryProvider] = useState(null)
  const [tilesetResource, setTilesetResource] = useState(null)
  const [earthToken, setEarthToken] = useState(null)
  const [position, setPosition] = useState(initialPosition)
  const [canFly, setCanFly] = useState(false)
  const [cameraDistance, setCameraDistance] = useState(20000000)
  const [placePos, setPlacePos] = useState(Cartesian3.fromDegrees(0, 0, 0))
  const [isViewerUpdated, setIsViewerUpdated] = useState(false)
  const [positionX, setPositionX] = useState(0)
  const [positionY, setPositionY] = useState(0)
  const [isShowInform, setIsShowInform] = useState(false)
  const [placeId, setPlaceId] = useState('0')
  const [placeName, setPlaceName] = useState('')
  const places = useSelector((state) => state.places)
  const viewerRef = useRef(null)
  const dispatch = useDispatch()
  const firstLoaded = useRef(true)

  useEffect(() => {
    get('/1/account/earth').then((response) => {
      if (response.success) {
        Ion.defaultAccessToken = response.data.token
        setEarthToken(response.data.token)
      }
    })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const getAllPlaces = () => {
    if (places.length !== 0) return
    get('/1/account/places').then((response) => {
      if (response.success) {
        dispatch({ type: 'GET_PLACES', places: response.data.places })
      }
    })
  }

  useEffect(() => {
    if (places.length === 0) return
    if (firstLoaded.current) {
      setPlaceId(places[0].id)
      setPlaceName(places[0].name)
      firstLoaded.current = false
    } else {
      setPlaceId('0')
      setPlaceName('')
    }
    const viewer = viewerRef.current.cesiumElement
    Promise.all(
      places.map((place) => {
        const cartographic = Cartesian3.fromDegrees(place.longitude, place.latitude, 0)
        return viewer.scene.clampToHeightMostDetailed([cartographic])
          .then((clampedPosition) => {
            const serializablePosition = [clampedPosition[0].x, clampedPosition[0].y, clampedPosition[0].z]
            return {
              id: place.id,
              position: serializablePosition
            }
          })
      })
    ).then((positionsArray) => {
      dispatch({ type: 'ADD_CLAMPED_POSITIONS', clampPositions: positionsArray })
      // console.log('Positions:', positionsArray)
    })
  }, [dispatch, places])

  useEffect(() => {
    if (earthToken) {
      async function createImageryProvider () {
        try {
          const provider = await IonImageryProvider.fromAssetId(3)
          setImageryProvider(provider)
        } catch (error) {
          console.error('Error creating imagery provider:', error)
        }
      }
      async function createTilesetResource () {
        try {
          const resource = await IonResource.fromAssetId(2275207)
          setTilesetResource(resource)
        } catch (error) {
          console.error('Error creating tileset resource:', error)
        }
      }
      createTilesetResource()
      createImageryProvider()
    }
  }, [earthToken])

  const updateOnViewChange = useCallback(() => {
    if (canFly) return
    const viewer = viewerRef.current.cesiumElement
    if (!viewer) return
    const currentPosition = viewer.camera.position
    const plane = viewer.entities.getById(placeId)
    if (!plane) return
    if (isShowInform) {
      const position2d = SceneTransforms.worldToWindowCoordinates(viewer.scene, plane.position._value)
      if (position2d !== undefined && position2d.x !== positionX && position2d.y !== positionY) {
        setPositionX(position2d.x)
        setPositionY(position2d.y)
      }
    }
    const distance = Cartesian3.distance(currentPosition, placePos)
    if (distance === cameraDistance) return
    if (distance < 1500) {
      setCameraDistance(1500)
    } else {
      setCameraDistance(distance)
    }
  }, [canFly, placeId, placePos, isShowInform, positionX, positionY, cameraDistance])

  useEffect(() => {
    const viewer = viewerRef.current
    viewer?.cesiumElement?.scene.postRender.addEventListener(updateOnViewChange)

    return () => {
      viewer?.cesiumElement?.scene.postRender.removeEventListener(updateOnViewChange)
    }
  }, [isViewerUpdated, updateOnViewChange])

  useEffect(() => {
    if (viewerRef.current && viewerRef.current.cesiumElement) {
      const viewer = viewerRef.current.cesiumElement
      viewer.render()
      const entityToSelect = viewer.entities.getById(placeId)
      viewer.selectedEntity = entityToSelect
    }
  }, [placeId])

  const handleFlyToDestination = (longitude, latitude, id) => {
    setCanFly(true)
    setPosition(Cartesian3.fromDegrees(longitude + 0.001, latitude - 0.008, 1000))
    setPlacePos(Cartesian3.fromDegrees(longitude, latitude, 150))
    setCameraDistance(1500)
    setIsShowInform(false)
    setPlaceId(id)
  }

  const handleOpenInform = () => {
    setIsShowInform(!isShowInform)
  }

  const handleSelectedEntityChange = (entity) => {
    if (entity) {
      setPlaceId(entity.id)
      const place = places.find((place) => place.id === entity.id)
      setPlaceName(place.name)
      setPlacePos(Cartesian3.fromDegrees(place.longitude, place.latitude, 150))
    }
  }

  return (
    <div style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'hidden' }}>
      {earthToken &&
        <Viewer
          ref={viewerRef}
          selectionIndicator={false}
          infoBox={false}
          timeline={false}
          animation={false}
          homeButton={false}
          baseLayerPicker={false}
          shadow
          debugShowBoundingVolume
          full
          onSelectedEntityChange={handleSelectedEntityChange}
          onUpdate={() => setIsViewerUpdated(true)}
        >
          {imageryProvider && tilesetResource && (
            <ImageryLayer>
              <Cesium3DTileset
                url={tilesetResource}
                dynamicScreenSpaceError
                dynamicScreenSpaceErrorDensity={0.00278}
                dynamicScreenSpaceErrorFactor={4.0}
                dynamicScreenSpaceErrorHeightFalloff={0.25}
                cullWithChildrenBounds
                onAllTilesLoad={getAllPlaces}
              />
            </ImageryLayer>
          )}
          {firstLoaded.current &&
            <CameraFlyTo
              duration={0}
              destination={position}
              orientation={initialOrientation}
            />}
          {
          canFly && position &&
            <CameraFlyTo
              duration={3}
              destination={position}
              orientation={{
                heading: CMath.toRadians(0),
                pitch: CMath.toRadians(-45),
                roll: 0
              }}
              onComplete={() => {
                setCanFly(false)
                setIsShowInform(true)
              }}
            />
          }
          {
            places && places.map((place) => {
              return (
                <EntityComponent
                  key={place.id}
                  id={place.id}
                  show={!canFly}
                  handleOpenInfrom={handleOpenInform}
                />
              )
            })
          }
        </Viewer>}
      <div>
        {isShowInform &&
          <EmbeddedWebPage
            id={placeId}
            positionX={positionX}
            positionY={positionY}
            title={placeName}
            handleOpen={handleOpenInform}
          />}
      </div>
      <div>
        <NavPanel canFly={canFly} handleClick={handleFlyToDestination} />
      </div>
    </div>
  )
}

export default Earth
