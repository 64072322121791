import PropTypes from 'prop-types'
import { Card, CardTitle, CardBody, CardText, Col, Row } from 'reactstrap'
import { deleteItem } from '../utils/httpAgent'
import { useDispatch, useSelector } from 'react-redux'
import AddPlaceModal from './add-one-place-modal'
import { useCallback, useState } from 'react'

const propTypes = {
  placeName: PropTypes.string,
  id: PropTypes.string,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  handleClick: PropTypes.func
}

const PlaceButton = ({ placeName = '', id = '', longitude = 0, latitude = 0, handleClick = () => {} }) => {
  const [modal, setModal] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [hoverEdit, setHoverEdit] = useState(false)
  const dispatch = useDispatch()
  const index = useSelector((state) => state.index)

  const toggle = (e) => {
    if (e) {
      e.stopPropagation()
    }
    setModal(!modal)
  }

  const handleMouseEnter = useCallback((setStateFn) => {
    if (index.role === 'admin') {
      setStateFn(true)
    }
  }, [index.role])

  const handleMouseLeave = useCallback((setStateFn) => {
    if (index.role === 'admin') {
      setStateFn(false)
    }
  }, [index.role])

  const handleDelete = (e) => {
    e.stopPropagation()
    const uri = `/1/account/place/delete?id=${id}`
    deleteItem(uri).then(response => {
      if (response.success) {
        dispatch({ type: 'DELETE_PLACE', place: { id } })
        dispatch({ type: 'DELETE_CLAMPED_POSITION', place: { id } })
      }
    }).catch(error => {
      console.error('Error deleting place:', error)
    })
  }

  return (
    <div>
      <Card
        className={hoverEdit ? '' : 'place-button'}
        style={{ width: '340px', border: '1px solid white' }}
        onClick={() => handleClick(longitude, latitude, id)}
        onMouseEnter={() => handleMouseEnter(setShowEdit)}
        onMouseLeave={() => handleMouseLeave(setShowEdit)}
      >
        <CardBody>
          <Row>
            <Col md='2'>
              <img src='/media/geo-alt-fill.svg' alt='place icon' style={{ width: '30px' }} />
            </Col>
            <Col>
              <Row>
                <Col md='9'>
                  <CardTitle tag='h5'>{placeName}</CardTitle>
                </Col>
                <Col md='1' className='d-flex justify-content-end'>
                  {showEdit &&
                    <div className='place-button-img'>
                      <img
                        src='/media/pen.svg'
                        alt='right arrow'
                        onClick={toggle}
                        onMouseEnter={() => handleMouseEnter(setHoverEdit)}
                        onMouseLeave={() => handleMouseLeave(setHoverEdit)}
                      />
                    </div>}
                </Col>
                <Col md='1' className='d-flex justify-content-end'>
                  {showEdit &&
                    <div className='place-button-img'>
                      <img
                        src='/media/trash.svg'
                        alt='right arrow'
                        onClick={handleDelete}
                        onMouseEnter={() => handleMouseEnter(setHoverEdit)}
                        onMouseLeave={() => handleMouseLeave(setHoverEdit)}
                      />
                    </div>}
                </Col>
              </Row>
              <CardText>經度: {longitude}, 緯度: {latitude}</CardText>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <AddPlaceModal mode='update' modal={modal} id={id} toggle={toggle} />
    </div>
  )
}

PlaceButton.propTypes = propTypes

export default PlaceButton
